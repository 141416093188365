<template>
  <v-dialog
    v-model="dialog"
    max-width="290"
  >
    <v-card class="text-center">
      <v-card-title 
        class="text-h5 justify-center"
      >
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <div
        v-if="alertText"
        class="alert-container"
      >
        <v-alert
          dense
          type="error"
        >
          {{ alertText }}
        </v-alert>
      </div>
      <v-card-actions>
        <v-btn
          v-if="confirmText"
          color="green darken-1"
          text
          :loading="isLoading"
          @click="confirmButtonClick()"
        >
          {{ confirmText }}
        </v-btn>
        <v-btn
          v-if="cancelText"
          color="green darken-1"
          text
          :loading="isLoading"
          @click="closeButtonClick()"
        >
          {{ cancelText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    alertText: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: this.isVisible,
    }
  },
  watch: {
    isVisible (value) {
      this.dialog = value
    },
    dialog (value) {
      this.$emit('is-visible', value)
    }
  },
  methods: {
    closeButtonClick(){
      this.dialog = false
    },
    confirmButtonClick(){
      this.$emit('confirm-btn-click')
    }
  }
}
</script>
<style lang="scss">
.alert-container {
  padding: 0 24px;
  .v-alert {
    margin-bottom: 0;
  }
}
</style>
export const mapFullAlternativeAddress = (addressObj) =>  {
    if (!addressObj) return null;
    let fullAlternativeAddress =
        ( addressObj.country ?  addressObj.country + ", " : "") +
        (addressObj.postal_code ? addressObj.postal_code + (addressObj.city ? " " : ", ") : "") +
        (addressObj.city ? addressObj.city + ", " : "");
    
    if (addressObj.city_details) {
        fullAlternativeAddress +=
        addressObj.city_details + ", ";
    }

    fullAlternativeAddress += addressObj.address1 + " ";
    
    fullAlternativeAddress += [
        addressObj.house_number,
        addressObj.floor_details,
        addressObj.appartment_details,
    ].filter(Boolean).join(" / ");

    return fullAlternativeAddress
}
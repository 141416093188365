/**
 * Vuex
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import drawer from './modules/drawer.js'
import company from './modules/company.js'
import driver from './modules/driver.js'
import customer from './modules/customer.js'
import product from './modules/product.js'
import user from './modules/user.js'
import timeslot from './modules/timeslot.js'
import delivery from './modules/delivery.js'
import order from './modules/order.js'
import customer_preferences from './modules/customer_preference.js'
import stats from './modules/stats.js'
import vehicles from './modules/vehicle.js'
import endclient from './modules/endclient.js'

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
    namespaced: true,
    actions,
    getters,
    modules: {
        drawer,
        company,
        driver,
        customer,
        product,
        user,
        timeslot,
        delivery,
        order,
        customer_preferences,
        stats,
        vehicles,
        endclient
    },
    mutations,
    state
})

export default store
import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify/lib';
//import theme from './plugins/theme'
import theme from './theme'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// Vue.use(Vuetify, {
//     icons: {
//         iconfont: 'mdi',
//     },
//     theme
// })

// export default new Vuetify({});
Vue.use(Vuetify)
const opts = {
    icons: {
        iconfont: 'md',
    },
    theme: {

        themes: {

            light: theme,

            dark: theme

        }

    }
}
const vuetify = new Vuetify(opts)

export default vuetify;
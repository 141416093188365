/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import { stringify as queryStringify } from 'query-string'

// state
const state = {
    customerList: [],
    customerListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
}

// getters
const getters = {
    customerList: state => state.customerList,
    customerListMeta: state => state.customerListMeta,
};

// actions
const actions = {
    getCustomerList({ commit }, paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                company_id,
                order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                sortBy: paginationOptions.sortBy[0] || '',
                page: paginationOptions.page,
                take:paginationOptions.itemsPerPage,
                q: paginationOptions.search || '',
            }

        if (!queryParams.company_id) delete queryParams.company_id
        
        const queryString = queryStringify(queryParams);
        
        return axiosInstance.get(`/customers?${queryString}`)
            .then(response => {
                if (response.status === 200) {
                    const customers = response.data.data
                    const meta = response.data.meta

                    commit('setCustomerList', { customers, meta })
                    return true;
                }
                return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('customer.getCustomerList() error: ' + e);
                }
                return false
            })
    },
    createCustomer({ commit }, customer) {
        customer.company_id = sessionStorage.getItem("company_id");
        return axiosInstance.post('/customers', customer)
            .then(response => {
                if (response.status === 200) {
                    if (response.data?.id) customer.id = response.data.id
                    commit('addCustomerToList', customer)
                    return '';
                } 
                return 'error';
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('customer.createCustomer() error: ' + e);
                }
                if (e.response.status === 422) {
                    return e.response.data?.message[0]?.property || 'error'
                }
                return 'error';
            })
    },
    updateCustomer({ commit }, customer) {
        try {
            const customerCopy = Object.assign({}, customer)
            customerCopy.company_id = customerCopy?.company?.id;
            if (!customerCopy.company_id) customerCopy.company_id = sessionStorage.getItem("company_id");
            delete customerCopy.company;
            return axiosInstance.put('/customers', customerCopy)
                .then(response => {
                    if (response.status === 200) {
                        commit('editCustomerInList', customer)
                        return '';
                    }
                    return 'error';
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('customer.updateCustomer() error: ' + e);
                    }
                    console.log(e)
                    if (e.response.status === 422) {
                        console.log(e.response.data?.message[0]?.property)
                        return e.response.data?.message[0]?.property || 'error'
                    }
                    return 'error';
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('customer.updateCustomer() error: ' + e);
            }
            return false
        }
    },
    updateCustomerLocation({ commit }, customerLocation) {
        return axiosInstance.put('/customers/location', customerLocation)
            .then(response => {
                if (response.status === 200) {
                    commit('editCustomerLocationInList', customerLocation)
                    commit('editCustomersLocationInOrderList', customerLocation)
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('customer.updateCustomerLocation() error: ' + e);
                }
                return false
            })
    },
    deleteCustomer({ commit }, id) {
        return axiosInstance.delete(`/customers/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteCustomerFromList', id)
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('customer.deleteCustomer() error: ' + e);
                }
                return false
            })
    },
    clearCustomerState({ commit }) {
        commit('clearState', state);
    }
};

//mutations
const mutations = {
    // setters
    setCustomerList(state, customerList) {
        state.customerList = customerList.customers
        state.customerListMeta = customerList.meta
    },

    // other
    deleteCustomerFromList(state, customerId) {
        state.customerList = state.customerList.filter(c => c.id !== customerId)
    },
    addCustomerToList(state, customer) {
        state.customerList.unshift(customer)
    },
    editCustomerInList(state, customer) {
        state.customerList = state.customerList.map(c => {
            if (c.id === customer.id) {
                return customer
            } return c
        })
    },
    editCustomerLocationInList(state, customerLocation) {
        state.customerList = state.customerList.map(c => {
            if (c.id === customerLocation.customer_id) {
                c.lat = customerLocation.lat
                c.lon = customerLocation.lon
                c.is_geocoded = true
            } return c
        })
    },
    clearState(state) {
        state.customerList = null
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
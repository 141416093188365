<template>
  <v-footer
    id="core-footer"
    app
    absolute
    height="40"
  >
    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }} Zwono
    </span>
  </v-footer>
</template>

<script>
export default {
}
</script>

<style>
#core-footer {
  z-index: 0;
  place-content: flex-end;
}
</style>

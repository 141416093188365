export default {
    auth_request(state) {
        state.authStatus = 'loading'
    },
    auth_success(state, { token, refreshToken, user, company_id, role, search_address_api }) {
        state.authStatus = 'success'
        state.token = token
        state.refreshToken = refreshToken
        state.user = user
        state.company_id = company_id
        state.role = role
        state.search_address_api = search_address_api
    },
    refresh_success(state, { token, refreshToken }) {
        state.authStatus = 'success'
        state.token = token
        state.refreshToken = refreshToken
    },
    set_search_address_api(state, search_address_api) {
        state.search_address_api = search_address_api
        sessionStorage.setItem('search_address_api', search_address_api)
    },
    auth_error(state) {
        state.authStatus = 'error'
    },
    logout(state) {
        state.authStatus = ''
        state.token = ''
        state.refreshToken = ''
        state.user = {}
        state.company_id = ''
        state.role = ''
        state.search_address_api = ''
    },
}

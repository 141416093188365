/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import { stringify as queryStringify } from 'query-string'

// state
const state = {
    timeslotList: [],
    timeslotListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
}

// getters
const getters = {
    timeslotList: state => state.timeslotList,
    timeslotListMeta: state => state.timeslotListMeta,
};

// actions
const actions = {
    getTimeslotList({ commit }, paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                company_id,
                order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                sortBy: paginationOptions.sortBy[0] || '',
                page: paginationOptions.page,
                take:paginationOptions.itemsPerPage,
                q: paginationOptions.search || '',
            }
        
        if (!queryParams?.company_id) delete queryParams.company_id
       
        const queryString = queryStringify(queryParams);

        return axiosInstance.get(`/timeslots?${queryString}`)
            .then(response => {
                if (response.status === 200) {
                    const timeslots = response.data.data;
                    const meta = response.data.meta

                    commit('setTimeslotList', { timeslots, meta })
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Timeslot list error:', error)
                }
                return false
            })
    },
    createTimeslot({ commit }, timeslot) {
        timeslot.company_id = sessionStorage.getItem('company_id');
        return axiosInstance.post('/timeslots', timeslot)
            .then(response => {
                if (response.status === 200) {
                    if (response.data?.id) timeslot.id = response.data.id
                    commit('addTimeslotToList', timeslot)
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Create timeslot error:', error)
                }
                return false
            })
    },
    updateTimeslot({ commit }, timeslot) {
        const timeslotCopy = Object.assign({}, timeslot)
        timeslotCopy.company_id = timeslotCopy?.company?.id;
        if (!timeslotCopy.company_id) timeslotCopy.company_id = sessionStorage.getItem('company_id');
        delete timeslotCopy?.company;
        return axiosInstance.put('/timeslots', timeslotCopy)
            .then(response => {
                if (response.status === 200) {
                    commit('editTimeslotInList', timeslot)
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Update timeslot error:', error)
                }
                return false
            })
    },
    deleteTimeslot({ commit }, id) {
        return axiosInstance.delete(`/timeslots/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteTimeslotFromList', id)
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Delete timeslot error:', error)
                }
                return false
            })
    },
    clearTimeslotState({ commit }) {
        commit('clearState', state);
    },
};

//mutations
const mutations = {
    // setters
    setTimeslotList(state, timeslots) {
        state.timeslotList = timeslots.timeslots
        state.timeslotListMeta = timeslots.meta
    },

    // other
    deleteTimeslotFromList(state, timeslotId) {
        state.timeslotList = state.timeslotList.filter(c => c.id !== timeslotId)
    },
    addTimeslotToList(state, timeslot) {
        state.timeslotList.unshift(timeslot)
    },
    editTimeslotInList(state, timeslot) {
        state.timeslotList = state.timeslotList.map(c => {
            if (c.id === timeslot.id) {
                return timeslot
            } return c
        })
    },

    // clear state
    clearState(state) {
        state.timeslotList = null
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
// state.js
export default {
    authStatus: '',
    token: sessionStorage.getItem('token') || '',
    refreshToken: sessionStorage.getItem('refreshToken') || '',
    role: sessionStorage.getItem('role') || '',
    company_id: sessionStorage.getItem('company_id') || '',
    user: JSON.parse(sessionStorage.getItem('user')) || {},
    search_address_api: sessionStorage.getItem('search_address_api') || '',
    deliveryStatusEnum: ['NotDelivered', 'Delivered'],
}

/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'

// state
const state = {
    endClientData: null,
    specialOfferProducts: [],
    endclientStatusItems: [
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'Declined', label: 'Declined' },
        { value: null, label: 'No status yet' }
    ]
}

// getters
const getters = {
    endClientData: state => state.endClientData,
    specialOfferProducts: state => state.specialOfferProducts,
    endclientStatusItems: state => state.endclientStatusItems,
};

// actions
const actions = {
    getEndClientData({ commit }, orderId) {
        try {
            const url = `/orders/endclient/${orderId}`;
            return axiosInstance.get(url)
                .then(response => {
                    if (response.status === 200) {
                        const endClientData = response.data;
                        commit('setEndClientData', { endClientData })
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('endclient.getOrder() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('endclient.getOrder() error: ' + e);
            }
            return false
        }

    },
    getSpecialOfferProductsForOrder({ commit }, orderId) {
        try {
            const url = `/orders/endclient/${orderId}/specialoffer`;
            return axiosInstance.get(url)
                .then(response => {
                    if (response.status === 200) {
                        const specialOfferProducts = response.data;
                        if (specialOfferProducts && specialOfferProducts !== null) {
                            for (var off of specialOfferProducts) {
                                var id = off.id;
                                axiosInstance
                                    .get(`/products/image/${id}`, { responseType: 'arraybuffer' })
                                    .then((response) => {
                                        if (response.status === 200) {
                                            var content_type = response.headers['content-type'];
                                            const buffer = Buffer.from(response.data, 'binary').toString('base64');
                                            var imageBase64 =
                                                `data:${content_type};charset=utf-8;base64,` + buffer;
                                            // btoa(String.fromCharCode(...new Uint8Array(response.data)));
                                            off.imageBase64 = imageBase64;
                                        }
                                    })
                                    .catch((e) => {
                                        if (process.env.NODE_ENV === 'development') {
                                            console.log('endclient.getSpecialOfferProductsForOrder() error: ' + e);
                                        }
                                        return false
                                    });
                            }
                        }
                        commit('setSpecialOfferProducts', { specialOfferProducts })
                        return true
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('endclient.getSpecialOfferProductsForOrder() error: ' + e);
            }
            return false
        }
    },
    updateStatus({ commit }, order) {
        axiosInstance.post('/orders/endclient', order)
            .then(response => {
                if (response.status === 200) {
                    commit('setEndClientStatus', order.end_client_status)
                    return true;
                }
                return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('endclient.getSpecialOfferProductsForOrder() error: ' + e);
                }
                return false
            })
    },
    clearEndClientState({ commit }) {
        commit('clearState', state);
    }
};

//mutations
const mutations = {
    // setters
    setEndClientData(state, endClientData) {
        state.endClientData = endClientData.endClientData
    },

    setEndClientStatus(state, status) {
        state.endClientData.endclient_status = status
    },

    setSpecialOfferProducts(state, specialOfferProducts) {
        state.specialOfferProducts = specialOfferProducts.specialOfferProducts
    },

    // other
    addEndClientItem(state, item) {
        // Check if item exists already, then we just increase the qty
        let stateItem = state.endClientData.items.find(i => i.id === item.id)
        if(stateItem){
            stateItem.qty = parseInt(stateItem.qty) + parseInt(item.qty)
            return;
        }
        
        state.endClientData.items.push(item);
    },

    removeEndClientItem(state, itemId) {
        state.endClientData.items = state.endClientData.items.filter(i => i.id !== itemId);
    },

    clearState(state) {
        state.endClientData = null;
        state.specialOfferProducts = [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
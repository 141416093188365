<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        xs12
        md12
        class="px-4"
      >
        <material-card
          class="z-edit-profile"
          color="general z-main-title"
          :title="$t('User.user-profile.title')"
          :text="$t('User.user-profile.subtitle')"
        >
          <v-form>
            <v-container py-0>
              <v-layout
                v-if="editUser"
                wrap
              >
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="editUser.company.company_name"
                    label="Company"
                    disabled
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="editUser.firstName"
                    label="First Name"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="editUser.lastName"
                    label="Last Name"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="editUser.email"
                    label="Email Address"
                    disabled
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="editUser.phone"
                    label="Phone"
                    :rules="[rules.phoneNumber]"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="editUser.role"
                    label="Role"
                    disabled
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-select
                    v-model="editUser.lang"
                    :items="languages"
                    label="Language"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                />
                <v-flex
                  xs12
                  md4
                />
                <v-flex
                  xs12
                  md4
                >
                  <img
                    :key="imageAvatarKey"
                    :src="imageAvatar"
                    style="max-width: 100%"
                  >
                  <v-file-input
                    show-size
                    :label="$t('Common.avatar')"
                    filled
                    prepend-icon="mdi-camera"
                    accept="image/*"
                    @change="setImg"
                  />
                </v-flex>
                <!-- <v-flex
                  xs12
                  md4
                >
                  <v-avatar size="100" style="max-width:100%;">
                    <v-img :src="imageAvatar"></v-img>
                  </v-avatar>
                </v-flex> -->
                <v-flex
                  xs12
                  text-xs-right
                >
                  <v-alert
                    v-model="showUserUpdateAlert"
                    dense
                    text
                    type="success"
                  >
                    {{ $t("Common.alerts.user-profile-updated") }}
                  </v-alert>
                  <v-alert
                    v-model="showUserUpdateErrorAlert"
                    dense
                    text
                    type="error"
                  >
                    {{ $t("Common.alerts.user-profile-not-updated") }}
                  </v-alert>
                  <v-alert
                    v-model="showUserAvatarAlert"
                    dense
                    text
                    type="success"
                  >
                    {{ $t("Common.alerts.user-picture-updated") }}
                  </v-alert>
                  <v-alert
                    v-model="showUserAvatarErrorAlert"
                    dense
                    text
                    type="error"
                  >
                    {{ $t("Common.alerts.user-picture-not-updated") }}
                  </v-alert>
                  <v-btn
                    class="mx-0"
                    color="zwonogreen"
                    :loading="isSaving"
                    @click="save"
                  >
                    <v-icon left>
                      mdi-account-arrow-up-outline
                    </v-icon>
                    {{ $t("User.user-profile.update-btn") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validationMixin from '../helper/validationMixin';

export default {
  mixins: [validationMixin],

  data: () => ({
    languages: [
      { value: "en", text: "English" },
      // { value: 'de', text: 'Deutsch' },
      // { value: 'sl-SI', text: 'Slovenski' },
      // { value: 'sr-Cyrl-RS', text: 'Српски (ћирилица)' },
      // { value: 'sr-Latn-RS', text: 'Srpski (latinica)' },
    ],
    editUser: undefined,
    imageAvatar: "",
    avatarInputFile: "",
    imageAvatarKey: 0,
    showUserUpdateAlert: false,
    showUserUpdateErrorAlert: false,
    showUserAvatarAlert: false,
    showUserAvatarErrorAlert: false,
    isSaving: false,
  }),
  computed: {
    ...mapGetters("user", ["me"]),
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions("user", [
      "getCurrentUser",
      "getUserList",
      "setAvatar",
      "getAvatar",
      "updateUser",
    ]),

    async initialize() {
      const [avatarRes] = await Promise.all([
        this.getAvatar(),
        this.getCurrentUser(),
        this.getUserList({ itemsPerPage: 'all', role: sessionStorage.getItem('role') }),
      ]);
      this.imageAvatar = avatarRes;
      this.editUser = this.me;
    },

    resetAlerts() {
      this.showUserUpdateAlert = false;
      this.showUserUpdateErrorAlert = false;
      this.showUserAvatarAlert = false;
      this.showUserAvatarErrorAlert = false;
    },

    async save() {
      this.resetAlerts();
      this.isSaving = true;
      const isUpdated = await this.updateUser(this.editUser);
      if (isUpdated) this.showUserUpdateAlert = true;
      else this.showUserUpdateErrorAlert = true;
      this.isSaving = false;
    },
    async setUserAvatar(image) {
      const isSet = await this.setAvatar(image);
      if (isSet) this.showUserAvatarAlert = true;
      else this.showUserAvatarErrorAlert = true;
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (res) => {
          resolve(res.target.result);
        };
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
      });
    },
    async setImg(file) {
      try {
        const image = await this.readFile(file);
        // For displaying image in view, we need only base64 encoded image!
        this.imageAvatar = image;
        // We should sent complete File to API!
        await this.setUserAvatar(file);
      } catch (err) {
        alert("There was a problem with changing picture!");
      }
    },
  },
};
</script>

/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

export default [
    // This section is primarily for the login but it allows you to add external other pages to be rendered outside the dashboard layout like the login
    //if you want to add more external routes make them in the children array
    { // using the named route option 
        path: '/',
        meta: {
            name: 'External',
            requiresAuth: false,
        },
        component: () =>
            import (`@/components/layout/ExternalView.vue`),
        children: [
            //any components in this path auto render in External
            {
                path: '', // you leave this blank if you want it to default to the parents path
                name: 'login',
                component: () =>
                    import (`@/components/external-views/LoginForm.vue`),
            },
        ],
    },

    {
        path: '/dashboard',
        meta: {
            name: 'dashboard-view',
            requiresAuth: true,
        },
        component: () =>
            import (`@/components/layout/DashboardView.vue`),
        children: [{
                path: '', //defaults to /dashboard if left blank
                meta: {
                    name: 'Dashboard',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/components/dashboard-views/Dashboard.vue`),
            },
            {
                path: 'user-profile', // ends up as /dashboard/user-profile
                meta: {
                    name: 'UserProfile',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/components/dashboard-views/UserProfile.vue`),
            },
            {
                path: 'companies',
                meta: {
                    name: 'Companies',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/company/CompanyList.vue`),
            },
            {
                path: 'drivers',
                meta: {
                    name: 'Drivers',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/driver/DriverList.vue`),
            },
            {
                path: 'customers',
                meta: {
                    name: 'Customers',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/customer/CustomerList.vue`),
            },
            {
                path: 'products',
                meta: {
                    name: 'Products',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/product/ProductList.vue`),
            },
            {
                path: 'users',
                meta: {
                    name: 'Users',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/user/UserList.vue`),
            },
            {
                path: 'timeslots',
                meta: {
                    name: 'Timeslots',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/timeslot/TimeslotList.vue`),
            },
            {
                path: 'deliveries',
                meta: {
                    name: 'Deliveries',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/delivery/DeliveryList.vue`),
            },
            {
                path: 'orders',
                meta: {
                    name: 'Orders',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/order/OrderList.vue`),
            },
            {
                path: 'customer_preferences',
                meta: {
                    name: 'CustomerPreferences',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/customer_preference/CustomerPreferenceList.vue`),
            },
            {
                path: 'vehicles',
                meta: {
                    name: 'Vehicles',
                    requiresAuth: true,
                },
                component: () =>
                    import (`@/modules/vehicle/VehicleList.vue`),
            }
        ],
        //per route guard if you dont want global
        // beforeEnter: (to, from, next) => {
        // 	// checks to see if you are trying to go to dashboard and are logged in
        // 	if (to.name !== 'dashboard' && store.getters.authorized) {
        // 		next('/dashboard');
        // 	} 
        // 	// sends you to login if you arent authorized
        // 	else if (to.name !== 'dashboard' && !store.getters.authorized) { //
        // 		next('/login');
        // 	}
        // 	else {
        // 		next(to.name); // this sends you to your original route if you arent trying to go to login
        // 	}
        // },
    },
    // Landing page for EndClient
    // URL format: /endclient?id=123456
    { // using the named route option 
        path: '/endclient',
        meta: {
            name: 'EndClient',
            requiresAuth: false,
        },
        component: () =>
            import (`@/components/layout/ExternalView.vue`),
        children: [
            //any components in this path auto render in External
            {
                path: '', // you leave this blank if you want it to default to the parents path
                name: 'EndClient',
                component: () =>
                    import (`@/components/external-views/EndClient.vue`),
            },
            {
                path: 'error',
                name: 'EndClient404',
                component: () =>
                    import (`@/components/external-views/EndClient404.vue`),
            },
            {
                path: 'status',
                name: 'EndClientStatus',
                component: () =>
                    import (`@/components/external-views/EndClientStatus.vue`),
            },
        ],
    },
    // This is a catch all aka page not found route. it will send you to the dashboard
    {
        path: '*',
        redirect: {
            name: 'catchAll',
            path: '/dashboard',
        },
        meta: {
            requiresAuth: false,
        },
    },
    //Error component fallback
    {
        path: '/:catchAll(.*)',
        component: () =>
            import (`@/components/error/NotFound.vue`),
        name: 'NotFound',
    },
];
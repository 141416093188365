/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import { stringify as queryStringify } from 'query-string'

const state = {
    productList: [],
    productListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
}

// getters
const getters = {
    productList: state => state.productList,
    productListMeta: state => state.productListMeta,
};

// actions
const actions = {
    getProductList({ commit }, paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                company_id,
                order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                sortBy: paginationOptions.sortBy[0] || '',
                page: paginationOptions.page,
                take:paginationOptions.itemsPerPage,
                q: paginationOptions.search || '',
            }

    if (!queryParams?.company_id) delete queryParams.company_id
    
    const queryString = queryStringify(queryParams);

        return axiosInstance.get(`/products?${queryString}`)
            .then(response => {
                if (response.status === 200) {
                    const products = response.data.data
                    const meta = response.data.meta
                    commit('setProductList', { products, meta })
                    return true
                } return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('product.getProductList() error: ' + e);
                }
                return false
            })
    },
    async createProduct({ commit }, product) {
        try {
            product.company_id = sessionStorage.getItem('company_id');
            if (!(product.price = parseFloat(product.price))) {
                product.price = 0;
            }

            if (!(product.action_price = parseFloat(product.action_price))) {
                product.action_price = 0;
            }

            delete product.image;
            let response = await axiosInstance.post('/products', product);

            if (response.status === 200) {
                const createProductResult = response.data;

                if (!createProductResult?.id) return

                product.id = createProductResult.id
                commit('addProductToList', createProductResult)

                if (product.imageInput) {

                    const formData = new FormData();
                    const image = product.imageInput;
                    formData.append('image', image);

                    response = await axiosInstance.post(`/products/image/${product.id}`, formData, {
                        headers: {
                            Accept: '*/*',
                            'Content-Type': 'multipart/form-data',
                        }
                    });

                    if (response.status === 200) {
                        return true;
                    }
                    return 'image'
                }
                return true
            }
            return false

        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('product.createProduct() error: ' + e);
            }
            return false
        }
    },
    async updateProduct({ commit }, product) {
        try {
            const productCopy = Object.assign({}, product)

            //Set company id
            productCopy.company_id = productCopy?.company?.id;
            if (!productCopy.company_id) productCopy.company_id = sessionStorage.getItem('company_id');
            delete productCopy?.company;

            // Parse prices
            if (!(productCopy.price = parseFloat(productCopy.price))) {
                productCopy.price = 0;
            }

            if (!(productCopy.action_price = parseFloat(productCopy.action_price))) {
                productCopy.action_price = 0;
            }

            let response = await axiosInstance.put('/products', productCopy);

            if (response.status === 200) {
                commit('editProductInList', product)

                if (productCopy.imageInput) {

                    const formData = new FormData();
                    const image = productCopy.imageInput;
                    formData.append('image', image);

                    response = await axiosInstance.post(`/products/image/${productCopy.id}`, formData, {
                        headers: {
                            Accept: '*/*',
                            'Content-Type': 'multipart/form-data',
                        }
                    });

                    if (response.status === 200) {
                        return true;
                    }
                    return 'image'
                }
                return true
            }
            return false

        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('product.updateProduct() error: ' + e);
            }
            return false
        }
    },
    deleteProduct({ commit }, id) {
        return axiosInstance.delete(`/products/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteProductFromList', id)
                    return true
                }
                return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('product.deleteProduct() error: ' + e);
                }
                return false
            })
    },

    clearProductState({ commit }) {
        commit('clearState', state);
    },
};

//mutations
const mutations = {
    // setters
    setProductList(state, productList) {
        state.productList = productList.products
        state.productListMeta = productList.meta
    },

    // other
    deleteProductFromList(state, productId) {
        state.productList = state.productList.map(c => {
            if (c.id === productId) {
                c.is_deleted = true
                return c
            } return c
        })
    },
    addProductToList(state, product) {
        state.productList.unshift(product)
    },
    editProductInList(state, product) {
        state.productList = state.productList.map(c => {
            if (c.id === product.id) {
                return product
            } return c
        })
    },

    // clear state
    clearState(state) {
        state.productList = [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
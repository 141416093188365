import { set, toggle } from './drawersettoggle'
//need to create some getters and replace some code in the drawer component files with this

const state = {
  drawer: null,
}

const getters = {
  drawer: state => state.drawer,
}

// actions
const actions = {};

//mutations
const mutations = {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  drawer: null,
  color: 'general',
  image: '', // change if you want a picture in the drawer but if you want to rid of the pictures, remove links here and in filter.vue
};

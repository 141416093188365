<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    class="z-dashboard"
  >
    <div
      v-if="spinnerActive"
      class="table-spinner-container flex-self-start"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        :size="100"
        :width="8"
      />
    </div>
    <v-flex
      v-else
      class="flex-self-start"
    >
      <v-flex
        v-if="!spinnerActive
          && !deliveriesInProgressList.length 
          && !ordersChartData.data.series.length
          && !customerStatsTop5OrdersChartData.data.series.length
          && !productCount30DaysChartData.data.series.length
          && !customerStatsTop5OrdersChartData.data.series.length
          && !customerStatsTop5PayingChartData.data.series.length"
        class="text-h5"
      >
        {{ $t("Common.no_data") }}
      </v-flex>
      <v-layout wrap>
        <v-flex
          v-for="delivery in deliveriesInProgressList"
          :key="delivery.id"
          md12
          sm12
          lg12
        >
          <material-delivery-card
            v-if="deliveriesInProgressList.length > 0"
            color="zwonogreen"
            class="z-card-table"
            :headers="headers"
            :data="delivery"
            icon="mdi-truck-delivery"
          />
        </v-flex>
        <v-flex
          v-if="ordersChartData.data.series.length"
          md12
          sm12
          lg6
        >
          <material-chart-card
            :data="ordersChartData.data"
            :options="ordersChartData.options"
            type="Line"
            color="zwonogreen"
            class="z-chart-panel"
          >
            <h4 class="title font-weight-light">
              {{ $t("Dashboard.order_statistics_label") }}
            </h4>
            <template slot="actions">
              <v-icon
                class="mr-2"
                small
              >
                mdi-clock-outline
              </v-icon>
              <span class="caption grey-lighten-1--text font-weight-light">{{
                $t("Dashboard.order_statistics_desc")
              }}</span>
            </template>
          </material-chart-card>
        </v-flex>
        <v-flex
          v-if="productCount30DaysChartData.data.series.length"
          md12
          sm12
          lg6
        >
          <material-chart-card
            :data="productCount30DaysChartData.data"
            :options="productCount30DaysChartData.options"
            type="Bar"
            color="zwonoyellowdark"
            class="z-chart-panel"
          >
            <h4 class="title font-weight-light">
              {{ $t("Dashboard.top5_products_label") }}
            </h4>
            <template slot="actions">
              <v-icon
                class="mr-2"
                small
              >
                mdi-clock-outline
              </v-icon>
              <span class="caption grey-lighten-1--text font-weight-light">{{
                $t("Dashboard.top5_products_desc")
              }}</span>
            </template>
          </material-chart-card>
        </v-flex>
        <v-flex
          v-if="customerStatsTop5OrdersChartData.data.series.length"
          md12
          sm12
          lg6
        >
          <material-chart-card
            :data="customerStatsTop5OrdersChartData.data"
            :options="customerStatsTop5OrdersChartData.options"
            type="Bar"
            color="zwonograydark"
            class="z-chart-panel"
          >
            <h4 class="title font-weight-light">
              {{ $t("Dashboard.top5_customers_orders_label") }}
            </h4>
            <template slot="actions">
              <v-icon
                class="mr-2"
                small
              >
                mdi-clock-outline
              </v-icon>
              <span class="caption grey-lighten-1--text font-weight-light">{{
                $t("Dashboard.top5_customers_orders_desc")
              }}</span>
            </template>
          </material-chart-card>
        </v-flex>
        <v-flex
          v-if="customerStatsTop5PayingChartData.data.series.length"
          md12
          sm12
          lg6
        >
          <material-chart-card
            :data="customerStatsTop5PayingChartData.data"
            :options="customerStatsTop5PayingChartData.options"
            type="Bar"
            color="info"
            class="z-chart-panel"
          >
            <h4 class="title font-weight-light">
              {{ $t("Dashboard.top5_customers_value_label") }}
            </h4>
            <template slot="actions">
              <v-icon
                class="mr-2"
                small
              >
                mdi-clock-outline
              </v-icon>
              <span class="caption grey-lighten-1--text font-weight-light">{{
                $t("Dashboard.top5_customers_value_desc")
              }}</span>
            </template>
          </material-chart-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Dashboard",
    data() {
        return {
            ordersChartData: {
                data: {
                    labels: [],
                    series: [],
                },
                options: {
                    lineSmooth: this.$chartist.Interpolation.cardinal({
                        tension: 0,
                    }),
                    low: 0,
                    // high: 6, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    chartPadding: {
                        top: 24,
                        right: 0,
                        bottom: 0,
                        left: 6,
                    },
                },
            },
            productCount30DaysChartData: {
                data: {
                    labels: [],
                    series: [],
                },
                options: {
                    // lineSmooth: this.$chartist.Interpolation.cardinal({
                    //   tension: 0
                    // }),
                    // low: 0,
                    // high: 6, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    // chartPadding: {
                    //   top: 0,
                    //   right: 0,
                    //   bottom: 0,
                    //   left: 0
                    // }
                    labelInterpolationFnc: function (value) {
                        return value[0];
                    },
                },
            },
            customerStatsTop5OrdersChartData: {
                data: {
                    labels: [],
                    series: [],
                },
                options: {
                    lineSmooth: this.$chartist.Interpolation.cardinal({
                        tension: 0,
                    }),
                    low: 0,
                    // high: 6, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    chartPadding: {
                        top: 24,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
            customerStatsTop5PayingChartData: {
                data: {
                    labels: [],
                    series: [],
                },
                options: {
                    lineSmooth: this.$chartist.Interpolation.cardinal({
                        tension: 0,
                    }),
                    low: 0,
                    // high: 6, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    chartPadding: {
                        top: 24,
                        right: 0,
                        bottom: 0,
                        left: 12,
                    },
                },
            },
            spinnerActive: false,
            headers: [
                { text: this.$t("Common.nr"), value: "nr", width: "10%" },
                { text: this.$t("Common.name"), value: "customer_name" },
                { text: this.$t("Common.phone"), value: "customer_phone" },
                { text: this.$t("Common.location"), value: "customer_location" },
                { text: this.$t("Common.full_alternative_address"), value: "full_alternative_address" },
                { text: this.$t("Common.timeslot"), value: "timeslot" },
                { text: this.$t("Common.status"), value: "status" },
                { text: this.$t("Common.note"), value: "driver_comment" },
            ],
        };
    },
    computed: {
        ...mapGetters("stats", [
            "orderStats",
            "productStatsCount30Days",
            "customerStatsTop5Orders",
            "customerStatsTop5Paying",
        ]),
        ...mapGetters("delivery", ["deliveriesInProgressList"]),
    },
    // lifecycle hooks
    created() {
        this.initialize();
    },
    beforeDestroy() {
        this.clearStatsState();
        this.clearDeliveryState();
    },
    sse: {
        cleanup: true,
    },
    methods: {
        ...mapActions("stats", [
            "getOrderStatsForCompany",
            "getMostOrderedItemsIn30DaysForCompany",
            "getCustomerTop5Orders",
            "getCustomerTop5Paying",
            "clearStatsState",
        ]),
        ...mapActions("delivery", [
            // "getDeliveriesInProgress",
            "getDeliveriesInProgressSSE",
            "clearDeliveryState",
        ]),
        async initialize() {
            this.spinnerActive = true;
            await this.fetch_data();
            this.prepareCharts();
            this.spinnerActive = false;
        },
        async fetch_data() {
            await Promise.all([
                this.getOrderStatsForCompany(),
                this.getMostOrderedItemsIn30DaysForCompany(),
                this.getCustomerTop5Orders(),
                this.getCustomerTop5Paying(),
                // this.getDeliveriesInProgress(),
                this.getDeliveriesInProgressSSE(this.$sse),
            ]);
        },
        prepareCharts() {
            this.prepare_data_order_stats();
            this.prepare_data_product_count_30_days_stats();
            this.prepare_data_customer_top_5_orders_stats();
            this.prepare_data_customer_top_5_paying_stats();
        },
        async prepare_data_order_stats() {
            if (this.orderStats) {
                this.ordersChartData.data.labels = this.orderStats.labels;
                if (this.orderStats.series?.length)
                    this.ordersChartData.data.series.push(this.orderStats.series);
            }
        },
        async prepare_data_product_count_30_days_stats() {
            if (this.productStatsCount30Days) {
                this.productCount30DaysChartData.data.labels =
                    this.productStatsCount30Days.labels;
                if (this.productStatsCount30Days.series?.length)
                    this.productCount30DaysChartData.data.series.push(this.productStatsCount30Days.series);
            }
        },
        async prepare_data_customer_top_5_orders_stats() {
            if (this.customerStatsTop5Orders) {
                this.customerStatsTop5OrdersChartData.data.labels =
                    this.customerStatsTop5Orders.labels;
                if (this.customerStatsTop5Orders.series?.length)
                    this.customerStatsTop5OrdersChartData.data.series.push(this.customerStatsTop5Orders.series);
            }
        },
        async prepare_data_customer_top_5_paying_stats() {
            if (this.customerStatsTop5Paying) {
                this.customerStatsTop5PayingChartData.data.labels =
                    this.customerStatsTop5Paying.labels;
                if (this.customerStatsTop5Paying.series?.length)
                    this.customerStatsTop5PayingChartData.data.series.push(this.customerStatsTop5Paying.series);
            }
        },
    },
};
</script>

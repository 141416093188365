<!-- This renders any components insidet the dashboard with the elements you expect -->
<template>
  <v-app 
    id="dashboard-view" 
    dark
    class="z-app"
  >
    <!-- <core-filter /> -->

    <core-toolbar />

    <core-drawer />

    <v-main class="z-main">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>

    <core-footer v-if="$route.meta.name !== 'Maps'" />
  </v-app>
</template>

<script>
//feel free to rename the title
export default {
  name: 'DashboardView',
  metaInfo () {
    return {
      title: 'Zwono'
    }
  }
}
</script>

<style>

</style>

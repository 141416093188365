
<template>
  <div>
    <div
      :id="mapContainerId"
      class="map-container"
    />
    <default-popup
      :title="$t('Common.warning')"
      :text="issueText"
      :confirm-text="$t('Common.ok')"
      cancel-text=""
      alert-text=""
      :is-visible="issueTextModalVisible"
      @is-visible="value => issueTextModalVisible = value"
      @confirm-btn-click="() => issueTextModalVisible = false"
    />
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
import maplibregl from 'maplibre-gl'

export default {
    name: 'MapLibre',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        route: {
            required: false
        },
        // eslint-disable-next-line vue/require-default-prop
        markers: { // { lat, lon } []
            required: false
        },
    },
    data: () => ({
        map: {},
        mapContainerId: 'map-' + Math.random().toString(36).substr(2, 9), // Generate a unique map container ID
        colors: [
            "#ff4d4d", "#1a8cff", "#00cc66", "#b300b3",
            "#e6b800", "#ff3385", "#0039e6", "#408000",
            "#ffa31a", "#990073", "#cccc00", "#cc5200",
            "#6666ff", "#009999"
        ],
        APIKey: '99f74f6814c149adb7dbd1d36756011b',
        issueTextModalVisible: false,
        issueText: '',
        mapMarkers: [],
    }),
    watch: {
        route(newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.initialize()
            }
        },
        markers(newValue, oldValue) { 
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) this.updateMarkers(newValue)
        },
    },
    mounted: async function () {
        this.initialize()
    },
    methods: {
        initialize() {
            const locationPoints = this.route?.properties.params.locations.map( l => { return { lon: l.location[0], lat: l.location[1]} }) || []
            const shipmentPoints = this.route?.properties.params.shipments.map( s => { return { lon: s.delivery.location[0], lat: s.delivery.location[1]} }) || []
            const allPoints = locationPoints.concat(shipmentPoints)
            
            this.map = new maplibregl.Map({
                container: this.mapContainerId,
                style: `https://maps.geoapify.com/v1/styles/klokantech-basic/style.json?apiKey=${this.APIKey}`,
                center: this.markers?.length
                    ? [this.markers[0].lon, this.markers[0].lat]
                    : allPoints?.length 
                        ? [ allPoints[0].lon, allPoints[0].lat]
                        : [ 1.675063, 47.751569 ],
                zoom: this.markers?.length || allPoints?.length ? 10 : 4
            });

            this.map.addControl(new maplibregl.NavigationControl());
            this.map.on('load', () => {
                const resizeEvent = new Event('resize');
                window.dispatchEvent(resizeEvent);
                // window.dispatchEvent(resizeEvent);
                if (this.route) {
                    this.visualizeLocations(this.route.properties.params);
                    this.notifyAboutIssues(this.route);
                    this.route.features.forEach((feature, index) => this.visualizeAgentWaypoints(feature, this.colors[index]));
                    this.route.features.forEach((feature, index) => this.visualizeAgentRoute(feature, this.colors[index], index));
                    this.fitMapToBounds(allPoints)
                }
                if (this.markers) {
                    this.updateMarkers(this.markers)
                }
            });
        },
        visualizeLocations(deliveryTask) {
            // collect unique locations
            const locationMap = {};

            deliveryTask.shipments.forEach(shipment => {
                const locations = [shipment.pickup, shipment.delivery];
                locations.forEach((location, index) => {
                    const locationStr = location.location_index >= 0 ? location.location_index.toString() : `${location.location[1]} ${location.location[0]}`;

                    locationMap[locationStr] = locationMap[locationStr] || {
                        location: location.location_index >= 0 ? deliveryTask.locations[location.location_index].location : location.location,
                        delivery: [],
                        pickup: []
                    };

                    if (index === 0) {
                        locationMap[locationStr].pickup.push(shipment.id);
                    } else {
                        locationMap[locationStr].delivery.push(shipment.id);
                    }
                });
            });
            // visualize location as a layer
            const geoJSONObj = {
                "type": "FeatureCollection",
                "features": Object.keys(locationMap).map(locationKey => {
                    return {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": locationMap[locationKey].location
                        }
                    }
                })
            };

            this.map.addSource('locations', {
                type: 'geojson',
                data: geoJSONObj
            });


            this.map.addLayer({
                'id': 'locations',
                'type': 'circle',
                'source': 'locations',
                'paint': {
                    'circle-radius': 5,
                    'circle-color': "#ff9933",
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#994d00',
                }
            });

        },
        updateMarkers(markers) {
            // Remove existing markers
            this.mapMarkers.forEach((m) => m.remove());
            
            // Add new markers
            markers.forEach((marker) => {
            const mapMarker = new maplibregl.Marker({
                color: this.colors[0],
            });

            mapMarker.setLngLat([marker.lon, marker.lat]).addTo(this.map);

            this.mapMarkers.push(mapMarker);
            });

            // Fit the map to the bounds of the new markers
            this.fitMapToBounds(markers);
        },

        fitMapToBounds(points) {
            if (!points?.length) {
                return;
            }

            if (points.length === 1) {
                this.map.flyTo({
                    center: [points[0].lon, points[0].lat],
                    zoom: 10,
                    padding: { top: 100, bottom: 100, left: 100, right: 100 },
                });
            } else {
                let minLon = points[0].lon;
                let maxLon = points[0].lon;
                let minLat = points[0].lat;
                let maxLat = points[0].lat;

                points.forEach(marker => {
                    minLon = Math.min(minLon, marker.lon);
                    maxLon = Math.max(maxLon, marker.lon);
                    minLat = Math.min(minLat, marker.lat);
                    maxLat = Math.max(maxLat, marker.lat);
                });

                const bounds = new maplibregl.LngLatBounds()
                    .extend([minLon, minLat])
                    .extend([maxLon, maxLat]);
                this.map.fitBounds(bounds, {
                    padding: { top: 75, bottom: 75, left: 75, right: 75 },
                });
            }
        },

        notifyAboutIssues(result) {
            if (result.properties.issues) {
                this.issueText = `The solution has issues: ${Object.keys(result.properties.issues).join(', ')}`;
                this.issueTextModalVisible = true
            } else {
                this.issueText = ''
                this.issueTextModalVisible = false
            }
        },
        visualizeAgentWaypoints(feature, color) {
            const waypoints = feature.properties.waypoints
                .map((waypoint, index) => {
                    return {
                        "type": "Feature",
                        "properties": {
                            index: index + 1
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": waypoint.location
                        }
                    }
                });

            // create points source + layer
            this.map.addSource(`agent-${feature.properties.agent_index}-waypoints`, {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    "features": waypoints
                }
            });

            this.map.addLayer({
                'id': `agent-${feature.properties.agent_index}-waypoints-circle`,
                'type': 'circle',
                'source': `agent-${feature.properties.agent_index}-waypoints`,
                'paint': {
                    'circle-radius': 10,
                    'circle-color': color,
                    'circle-stroke-width': 1,
                    'circle-stroke-color': "rgba(0,0,0,0.2)"
                }
            });

            this.map.addLayer({
                'id': `agent-${feature.properties.agent_index}-waypoints-text`,
                'type': 'symbol',
                'source': `agent-${feature.properties.agent_index}-waypoints`,
                'layout': {
                    "text-field": '{index}',
                    'text-allow-overlap': false,
                    "text-font": [
                        "Roboto", "Helvetica Neue", "sans-serif"
                    ],
                    "text-size": 12
                },
                'paint': {
                    "text-color": "rgba(255, 255, 255, 1)"
                }
            });

        },
        visualizeAgentRoute(feature, color, index) {
            const lineWidth = 7 - index;
            const shift = -2 + index * 2;

            // generate a route and visualite it
            const waypoints = feature.properties.waypoints.map(waypoint => waypoint.location[1] + ',' + waypoint.location[0]).join('|');
            fetch(`https://api.geoapify.com/v1/routing?waypoints=${waypoints}&mode=drive&apiKey=${this.APIKey}`)
                .then(res => res.json())
                .then(res => {
                    this.map.addSource(`agent-${feature.properties.agent_index}-route`, {
                        type: 'geojson',
                        data: res
                    });

                    this.map.addLayer({
                        'id': `agent-${feature.properties.agent_index}-route`,
                        'type': 'line',
                        'source': `agent-${feature.properties.agent_index}-route`,
                        'layout': {
                            'line-cap': "round",
                            'line-join': "round"
                        },
                        'paint': {
                            'line-color': color,
                            'line-width': lineWidth,
                            'line-translate': [shift, shift]
                        }
                    });

                    this.map.moveLayer(`agent-${feature.properties.agent_index}-waypoints-circle`);
                    this.map.moveLayer(`agent-${feature.properties.agent_index}-waypoints-text`);
                });
        }
    }
}
</script>

<style scoped>
.map-container {
  z-index: 0;
  height: 600px;
}
</style>

import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress';
import store from '@/store'

export const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL;
axiosInstance.defaults.headers.get['Accept'] = 'application/json';

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
    refreshSubscribers.map(callback => callback(token));
}

function addRefreshSubscriber(callback) {
    refreshSubscribers.push(callback);
}

axiosInstance.interceptors.request.use(
    function(request) {
        const token = sessionStorage.getItem('token');
        if (token) {
            request.headers.common['Authorization'] = 'Bearer ' + token;
        }

        NProgress.start();
        return request;
    },
    function(error) {
        if (process.env.NODE_ENV === 'development') {
            console.log('axios request interceptor error: ' + error);
        }
        NProgress.done();
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function(response) {
        NProgress.done();
        return response;
    },
    async function(error) {
        const { config, response: { status } } = error;

        if (status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                store.dispatch('refreshtoken').then(newToken => {
                    isRefreshing = false;
                    onRefreshed(newToken);
                    refreshSubscribers = [];
                }).catch(() => {
                    store.dispatch('logout');
                });
            }

            return new Promise((resolve) => {
                addRefreshSubscriber(token => {
                    config.headers['Authorization'] = 'Bearer ' + token;
                    resolve(axiosInstance(config));
                });
            });
        } else if (status === 403) {
            await store.dispatch('logout');
        }

        if (process.env.NODE_ENV === 'development') {
            console.log('axios response interceptor error: ' + error);
        }
        NProgress.done();
        return Promise.reject(error);
    }
);

Vue.prototype.$http = axiosInstance;

export default axiosInstance;

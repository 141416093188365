/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import { stringify as queryStringify } from 'query-string'

// state
const state = {
    order: {},
    orderList: [],
    orderListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    ordersForDelivery: {},
    orderStatusItems: [
        { value: 'Ordered', label: 'Ordered' },
        { value: 'InProgress', label: 'In Progress' },
        { value: 'Delivered', label: 'Delivered' },
        { value: 'NotDelivered', label: 'Not Delivered' },
    ],
    endclientStatusItems: [
        { value: 'Declined', label: 'Declined' },
        { value: 'Confirmed', label: 'Confirmed' },
        { value: null, label: '' },
    ]
}

// getters
const getters = {
    order: state => state.order,
    orderList: state => state.orderList,
    orderListMeta: state => state.orderListMeta,
    ordersForDelivery: state => state.ordersForDelivery,
    orderStatusItems: state => state.orderStatusItems,
    endclientStatusItems: state => state.endclientStatusItems,
};

// actions
const actions = {
    getOrderList({ commit },  paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const filtersQueryString = paginationOptions.filters['customer.is_geocoded'] === false
            ? queryStringify(paginationOptions.filters)
            : null; 
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                company_id,
                order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                sortBy: paginationOptions.sortBy[0] || '',
                page: paginationOptions.page,
                take:paginationOptions.itemsPerPage,
                q: paginationOptions.search || '',
                filters: filtersQueryString
            }

        if (!queryParams?.company_id) delete queryParams.company_id
        if (!queryParams?.filters) delete queryParams.filters
        
        const queryString = queryStringify(queryParams);
        
            return axiosInstance.get(`/orders?${queryString}`)
                .then(response => {
                    if (response.status === 200) {
                        const orders = response.data.data;
                        const meta = response.data.meta
                        commit('setOrderList', { orders, meta })
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('order.getOrderList() error: ' + e);
                    }
                    return false
                })
    },
    getOrderedOrdersForCompany({ commit }) {
        try {
            let company_id = sessionStorage.getItem('company_id');
            return axiosInstance.get(`/orders?status=Ordered&company_id=${company_id}&is_geocoded=true&is_not_mapped=true&take=30000`)
                .then(response => {
                    if (response.status === 200) {
                        const orders = response.data.data;
                        commit('setOrderList', { orders })
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('order.getOrderedOrdersForCompany() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('order.getOrderedOrdersForCompany() error: ' + e);
            }
            return false
        }
    },
    getOrder({ commit }, order_id) {
        return axiosInstance.get(`/orders/${order_id}`)
            .then(response => {
                if (response.status === 200) {
                    const order = response.data;
                    commit('setOrder', { order })
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('order.getOrder() error: ' + e);
                }
                return false
            })

    },
    saveOrdersToDelivery({ commit }, delivery_order) { // MATEJ TODO: Dodaj možnost da spremeniš timeslot orderju
        try {
            let obj = {
                delivery_id: delivery_order.deliveryId,
                order_ids_with_timeslots: delivery_order.order_ids_with_timeslots,
                notify_end_client: delivery_order.notify_end_client,
                is_set_status_planned: delivery_order.is_set_status_planned,
                timeslot_changes: JSON.stringify(delivery_order.timeslot_changes)
            }
            
            if (delivery_order.firstOrderToDeliverId) obj.first_order_to_deliver = delivery_order.firstOrderToDeliverId

            return axiosInstance.post('/orders/delivery', obj)
                .then(response => {
                    if (response.status === 200) if (response.status === 200) {
                        const order = response.data;
                        commit('delivery/setDeliveryStatusInList', { ...obj, status: 'Planned' }, { root: true })
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('order.saveOrdersToDelivery() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('order.saveOrdersToDelivery() error: ' + e);
            }
            return false
        }
    },
    getOrdersForDelivery({ commit }, { delivery_id, only_not_declined }) {
        try {
            let url = `/orders/delivery/${delivery_id}`;
            if (only_not_declined) {
                url += '?only_not_declined=true';
            }
            return axiosInstance.get(url)
                .then(response => {
                    if (response.status === 200) {
                        const ordersForDelivery = response.data;
                        commit('setOrdersForDelivery', { ordersForDelivery })
                    }
                    return response;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('order.getOrdersForDelivery() error: ' + e);
                    }
                    return e
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('order.getOrdersForDelivery() error: ' + e);
            }
            return e
        }

    },
    createOrder({ commit }, order) {
        try {
            order.company_id = sessionStorage.getItem('company_id');
            if (order?.price) {
                order.price = parseFloat(order.price);
            } else {
                order.price = 0;
            }
            order.status = 'Ordered';
            return axiosInstance.post('/orders', order)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data?.id) order.id = response.data.id
                        commit('addOrderToList', order)
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('order.createOrder() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('order.createOrder() error: ' + e);
            }
            return false
        }
    },
    updateOrder({ commit }, order) {
        order.company_id = sessionStorage.getItem("company_id");
        if (order.price) order.price = parseInt(order.price);

        if (!order.customer_id) order.customer_id = order?.customer?.id;
        if (!order.delivery_id) order.delivery_id = order?.delivery?.id;
        try {
            return axiosInstance.put('/orders', order)
                .then(response => {
                    if (response.status === 200) {
                        commit('editOrderInList', order)
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('order.updateOrder() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('order.updateOrder() error: ' + e);
            }
            return false
        }
    },
    deleteOrder({ commit }, id) {
        return axiosInstance.delete(`/orders/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteOrderFromList', id)
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('order.deleteOrder() error: ' + e);
                }
                return false
            })
    },
    setAlternativeAddress({ commit }, alt_addr) {
        return axiosInstance.post('/orders/alternative_address', alt_addr)
            .then(response => {
                if (response.status === 200) {
                    commit('editOrderCustomerProperties', alt_addr)
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('order.setAlternativeAddress() error: ' + e);
                }
                return false
            })
    },
    setOrdersOrderNumberInDelivery({ commit }, ordersObject) { // {id, order_number_in_delivery}[]
        return axiosInstance.put('/orders/deliveries_order', ordersObject)
            .then(response => {
                if (response.status === 200) {
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('order.setOrdersOrderNumberInDelivery() error: ' + e);
                }
                return false
            })
    },
    deleteAlternativeAddress({ commit }, object) {
        return axiosInstance.post('/orders/delete_alternative_address', { order_id: object.order_id, is_user_change: object.is_user_change })
            .then(response => {
                if (response.status === 200) {
                    commit('editOrderCustomerProperties', { order_id: object.orderId, alternative_address: null })
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('order.deleteAlternativeAddress() error: ' + e);
                }
                return false
            })
    },
    clearDeliveryData({ commit }, orderId) {
        try {
            const url = `/orders/clear_delivery_data/${orderId}`;
            return axiosInstance.put(url)
                .then(response => {
                    if (response.status === 200) {
                        commit('deleteOrderDeliveryFromList', orderId)
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('order.clearDeliveryData() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('order.clearDeliveryData() error: ' + e);
            }
            return false
        }
    },
    clearOrderState({ commit }) {
        commit('clearState', state);
    },
};

// mutations
const mutations = {
    // setters
    setOrder(state, order) {
        state.order = order.order
    },
    setOrderList(state, orderList) {
        state.orderList = orderList.orders
        state.orderListMeta = orderList.meta
    },
    setOrdersForDelivery(state, ordersForDelivery) {
        state.ordersForDelivery = ordersForDelivery.ordersForDelivery
    },

    // other
    deleteOrderDeliveryFromList(state, orderId) {
        state.orderList = state.orderList.map(c => {
            if (c.id === orderId) {
                delete c.delivery
            } return c
        })
    },

    editOrderCustomerProperties(state, customerProperties) {
        state.orderList = state.orderList.map(c => {
            if (c.id === customerProperties.order_id) {
                delete customerProperties.customer_id
                delete customerProperties.order_id
                for (var prop in customerProperties) c.customer[prop] = customerProperties[prop];
            } return c
        })
    },

    addOrderToList(state, order) {
        state.orderList.unshift(order)
    },

    editOrderInList(state, order) {
        state.orderList = state.orderList.map(c => {
            if (c.id === order.id) {
                return {...c, ...order}
            } return c
        })
    },

    changeAlternativeAddressInOrderList(state, object) {
        state.orderList = state.orderList.map(c => {
            if (c.id === object.orderId) {
                return {...c, alternative_address: object.alternativeAddress}
            } return c
        })
    },

    changeAlternativeAddressInOrdersForDelivery(state, object) {
        state.ordersForDelivery.orders = state.ordersForDelivery.orders.map(c => {
            if (c.id === object.orderId) {
                return {...c, alternative_address: object.alternativeAddress}
            } return c
        })
    },

    deleteOrderFromList(state, orderId) {
        state.orderList = state.orderList.filter(c => c.id !== orderId)
    },

    editCustomersLocationInOrderList(state, customerLocation) {
        state.orderList = state.orderList.map(o => {
            if (o.customer.id === customerLocation.customer_id) {
                o.customer.lat = customerLocation.lat
                o.customer.lon = customerLocation.lon
                o.is_geocoded = true
            } return o
        })
    },

    clearState(state) {
        state.order = {};
        state.orderList = [];
        state.ordersForDelivery = {};
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
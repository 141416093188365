export default {
    data() {
        return {
            rules: {
                required: (value) => !!value || this.$t("Common.validation_required"),
                email: (value) => {
                    if(!value) return true
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return (
                        pattern.test(value) || this.$t("Common.validation_email_invalid")
                    );
                },
                phoneNumber: (value) => {
                    if (!value) return true;
                    
                    const PHONE_FORMATS = [
                        // uncomment if you want to allow phone numbers without country code.
                        // /^0\d{8,9}$/, // 031123123 or 0311231234
                        // /^0\d{2}-\d{3}-\d{3,4}$/, // 031-123-123 or 031-123-1234
                        // /^0\d{2} \d{3} \d{3,4}$/, // 031 1234 123 or 031 1234 1234
                        /^\+\d{3} \d{2} \d{3} \d{3,4}$/, // +386 31 123 123 or +386 31 123 1234
                        /^\+\d{3}-\d{2}-\d{3}-\d{3,4}$/, // +386-31-123-123 or +386-31-123-1234
                        /^\+\d{11,12}$/, // +38631123123 or +386311231234
                        /^00 \d{3} \d{2} \d{3} \d{3,4}$/, // 00 386 31 123 123 or 00 386 31 123 1234
                        /^00-\d{3}-\d{2}-\d{3}-\d{3,4}$/, // 00-386-31-123-123 or 00-386-31-123-1234
                        /^00\d{11,12}$/, // 0038631123123 or 00386311231234
                        /^\+38\s?\d(\s?\d){8,9}$/,
                        /^0038\s?\d(\s?\d){8,9}$/,
                    ];

                    return (
                        PHONE_FORMATS.some((format) => format.test(value)) || this.$t("Common.validation_phone_invalid")
                    );
                },
                minLength6: (value) => {
                    if (value?.length < 6) return this.$t("Common.validation_min_lenght_6");
                    return true;
                },
                isNumber: (value) => {
                    if (!isNaN(value)) return true;
                    return this.$t("Common.validation_is_number");
                },
                length4: (value) => {
                    if (value?.length === 4) return true;
                    return this.$t("Common.validation_lenght_4");
                },
                latitude: (value) => {
                if (value === null || value === '') return true; // assuming empty is valid, adjust as needed
                const num = parseFloat(value);
                return (!isNaN(num) && num >= -90 && num <= 90) || this.$t("Common.validation_latitude_invalid");
                },
                
                longitude: (value) => {
                if (value === null || value === '') return true; // assuming empty is valid, adjust as needed
                const num = parseFloat(value);
                return (!isNaN(num) && num >= -180 && num <= 180) || this.$t("Common.validation_longitude_invalid");
                },
            }
        }
    }
}
<template>
  <v-dialog
    v-model="dialog"
    max-width="1200"
  >
    <v-card>
      <v-card-title class="text-h5 z-card-title">
        <v-icon left>
          mdi-truck-fast-outline
        </v-icon>
        {{ $t("Common.alternative_address") + (customerName ? `: ${customerName}` : '') }}
        <v-spacer />
        <v-btn
          icon
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-autocomplete
          v-model="selectedAddress"
          :items="addressSuggestions"
          :no-filter="true"
          :label="$t('Customer.enter_new_address')"
          :search-input.sync="searchText"
          item-text="label"
          :return-object="true"
          :hide-no-data="true"
          :loading="isLoading"
          :no-data-text="$t('Common.no_data')"
        />
        <MapLibre
          v-if="isVisible"
          :markers="selectedCoordinates ? [selectedCoordinates] : []"
        />
        <div
          v-if="alertText"
          class="alert-container mt-5"
        >
          <v-alert
            dense
            type="error"
          >
            {{ alertText }}
          </v-alert>
        </div>
        <v-card-actions
          class="d-flex justify-center"
        >
          <v-btn
            class="mx-0"
            color="zwonogreen"
            :loading="isSaving"
            :disabled="!selectedCoordinates"
            @click="saveAlternativeAddress"
          >
            {{ $t("Common.save") }}
          </v-btn>
          <v-btn 
            class="mx-0 ml-5"
            color="warning"
            :loading="isSaving"
            @click="deleteAlternativeAddressLocal"
          >
            {{ $t("Common.delete_alternative_address") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import MapLibre from "@/components/maplibre/MapLibre.vue";
import { mapActions } from "vuex";
import { fetchAddressSuggestions, searchLocationPlanPlus, getLocationObject, getStreetPlanPlus } from '../../helpers/address-search-helpers.js'
import { mapGetters } from "vuex";

export default {
  components: {
    MapLibre,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    orderId: {
      type: String,
      required: true
    },
    customerName: {
      type: String,
      required: true
    },
    isUserChange: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data () {
    return {
      dialog: this.isVisible,
      searchText: null,
      selectedAddress: null,
      selectedCoordinates: null,
      foundLocation: null,
      searchedLocation: null,
      lastSnp: null,
      autocompleteLocationObject: null,
      addressSuggestions: [],
      isLoading: false,
      isSaving: false,
      alertText: '',
    }
  },
  computed: {
    ...mapGetters(["getSearchAddressApi"]),
  },
  watch: {
    isVisible (value) {
      this.dialog = value
    },
    dialog (value) {
      this.$emit('is-visible', value)
    },
    searchText (text) {
      if(this.isVisible) this.fetchAddressSuggestions(text, this.getSearchAddressApi)
    },
    async selectedAddress(value) {
      let isLocationFound = false;
      const apiType = value?.value?.apiType
          switch(apiType) {
            case 'Planplus':
              if (value?.value?.code && value?.value?.type) {
                const type = value?.value?.type
                let location = null;
                switch (type) {
                  case 1:
                    location = await this.getStreetPlanPlus(value?.value?.code, this.lastSnp.number)
                    break;
                  case 2:
                    location = await this.getLocationObject(value?.value?.code, this.getSearchAddressApi);
                    break;
                  default:
                    break;
                }
                if (location) {
                      const coordinates = (location.lat && (location.lon || location.lng)) ? { lat: location.lat, lon: location.lon || location.lng } : null
                      this.selectedCoordinates = coordinates;
                      isLocationFound = !!coordinates
                      this.foundLocation = location;
                      if (value?.label) this.searchedLocation = await this.searchLocationPlanPlus(value.label);
                } else if (this.autocompleteLocationObject) {
                  const coordinates = ( this.autocompleteLocationObject.lat && ( this.autocompleteLocationObject.lon || this.autocompleteLocationObject.lng))
                    ? { lat: this.autocompleteLocationObject.lat, lon: this.autocompleteLocationObject.lon || this.autocompleteLocationObject.lng }
                    : null
                  
                  this.selectedCoordinates = coordinates;
                  isLocationFound = !!coordinates
                  this.foundLocation = this.autocompleteLocationObject;
                }
              } 
              
              if (!isLocationFound && value?.label) {
                const location = this.searchedLocation;
                const coordinates = (location.lat && (location.lon || location.lng)) ? { lat: location.lat, lon: location.lon || location.lng } : null
                this.selectedCoordinates = coordinates;
              }
              break;
            case 'Geoapify':
            if (value?.value?.code) {
                const location = await this.getLocationObject(value?.value?.code, this.getSearchAddressApi);
                if (location) {
                  const coordinates = (location.lat && (location.lon || location.lng)) ? { lat: location.lat, lon: location.lon || location.lng } : null
                  
                  this.selectedCoordinates = coordinates;
                  isLocationFound = !!coordinates
                  this.foundLocation = location;
                } else if (this.autocompleteLocationObject) {
                  const coordinates = ( this.autocompleteLocationObject.lat && ( this.autocompleteLocationObject.lon || this.autocompleteLocationObject.lng))
                    ? { lat: this.autocompleteLocationObject.lat, lon: this.autocompleteLocationObject.lon || this.autocompleteLocationObject.lng }
                    : null
                  
                  this.selectedCoordinates = coordinates;
                  isLocationFound = !!coordinates
                  this.foundLocation = this.autocompleteLocationObject;
                }
              } 
              break;
            default:
              break;
          }
    }
  },
  methods: {
    ...mapActions("order", [
      "setAlternativeAddress",
      "deleteAlternativeAddress"
    ]),
    async fetchAddressSuggestions(searchText, searchAddressApi) {
      if(searchText) {
        this.isLoading = true;
        const res = await fetchAddressSuggestions(searchText, searchAddressApi)
        this.isLoading = false;
        this.lastSnp = res.lastSnp;
        this.addressSuggestions = res.addressSuggestions;
        this.autocompleteLocationObject = res.autocompleteLocationObject;
        return res;
      }
    },
    async searchLocationPlanPlus(searchText) {
      this.isLoading = true;
        const res = await searchLocationPlanPlus(searchText)
        this.isLoading = false;
        return res;
    },
    async getLocationObject(id, searchAddressApi) {
      this.isLoading = true;
        const res = await getLocationObject(id, searchAddressApi)
        this.isLoading = false;
        return res;
    },
    async getStreetPlanPlus(id, number) {
      this.isLoading = true;
        const res = await getStreetPlanPlus(id, number)
        this.isLoading = false;
        return res;
    },
    async saveAlternativeAddress() {
      this.isSaving = true;
      const addressObj = {
        order_id: this.orderId,
        country: this.foundLocation?.country || this.foundLocation?.apiType === 'Planplus' ? 'RS' : null,
        city: this.foundLocation?.city || null,
        postal_code: this.foundLocation?.postal_code || this.searchedLocation?.postalCode || null,
        city_details: '',
        address1:  this.foundLocation?.address1 || this.searchedLocation?.title || null,
        house_number: this.foundLocation?.house_number  || null,
        floor_details: '',
        appartment_details:  this.foundLocation?.appartment_details || null,
        lat: this.selectedCoordinates.lat,
        lon: this.selectedCoordinates.lon,
        is_user_change: this.isUserChange,
      }

      if (!addressObj.city && this.lastSnp?.place) addressObj.city = this.lastSnp?.place
      if (!addressObj.address1 && this.lastSnp?.street) addressObj.address1 = this.lastSnp.street
      if (!addressObj.house_number && this.lastSnp?.number) addressObj.house_number = this.lastSnp?.number

      const isUpdated = await this.setAlternativeAddress(addressObj);
      this.isSaving = false;
      if (isUpdated) {
        this.$emit('is-changed', { orderId: this.orderId, addressObj})
        this.closeDialog()
      }
      else this.alertText = this.$t('Common.errors.unknown-error')
    },

    async deleteAlternativeAddressLocal() {
      const isUpdated = await this.deleteAlternativeAddress( { order_id: this.orderId, is_user_change: this.isUserChange } );
        this.isSaving = false;
        if (isUpdated) {
          this.$emit('is-changed', { orderId: this.orderId, addressObject: null})
          this.closeDialog()
        }
        else this.alertText = this.$t('Common.errors.unknown-error')
    },
    closeDialog() {
      this.dialog = false
      this.searchText= null
      this.selectedAddress= null
      this.selectedCoordinates= null
      this.foundLocation= null
      this.searchedLocation= null
      this.lastSnp= null
      this.autocompleteLocationObject= null
      this.addressSuggestions= []
      this.isLoading= false
      this.isSaving= false
      this.alertText= ''
    },
  }
}
</script>